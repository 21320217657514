import * as React from 'react';
import PageTemplate from '../components/pageTemplate'
import Link from "../components/link";
import routes, {getLocalizedRoute, LocationContext} from "../routes";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";
import homeBanner from "../assets/images/GCCI-UniGraz_Img_c_EUM04_NASA02_USGS91_KLGA02_WEGC04.png";
import SEO from "../components/seo";

const Home = ({location}) => {
  const intl = useIntl();

  const carbManageDe = "https://wegcwp.uni-graz.at/carbmanage/de/cm-de/";
  const carbManageEn = "https://wegcwp.uni-graz.at/carbmanage/";
  let carbManageUrl;
  if (intl.locale === "de") {
    carbManageUrl = carbManageDe;
  } else {
    carbManageUrl = carbManageEn;
  }
  const wegcLink = intl.locale === "de" ? "https://wegcenter.uni-graz.at/de/" : "https://wegcenter.uni-graz.at/en/"
  const uniGrazLink = intl.locale === "de" ? "https://www.uni-graz.at/de/" : "https://www.uni-graz.at/en/"
  const climateChangeGrazLink = intl.locale === "de" ? "https://climate-change.uni-graz.at/de/" : "https://climate-change.uni-graz.at/en/"

  return <LocationContext.Provider value={location}>
    <SEO title="GCCI"/>
    <PageTemplate title="Welcome to the Graz Climate Change Indicators portal GCCI.Earth!">
      <img src={homeBanner} alt="homeBanner" width="100%"/>
      <p style={{marginTop: "25px", fontWeight: "bolder"}}>{intl.formatMessage({id: "home.title"})}</p>
      <p>
        <FormattedMessage id={"home.p1.s1"} values={{
          wegcLink: <Link href={wegcLink}>Wegener Center</Link>,
          uniGrazLink: <Link href={uniGrazLink}><FormattedMessage id={"uniGraz"}/></Link>,
          fieldExcLink: <Link href={climateChangeGrazLink}>Climate Change Graz</Link>,
        }}/>{" "}
        {intl.formatMessage({id: "home.p1.s2"})}{" "}
        {intl.formatMessage({id: "home.p1.s3"})}
      </p>
      <p>
        <FormattedMessage id={"home.p2.s1"} values={{b: (...chunks) => <b>{chunks}</b>}}/>{" "}
        <FormattedMessage id={"home.p2.s2"} values={{b: (...chunks) => <b>{chunks}</b>}}/>
      </p>
      <p>
        <FormattedMessage id={"home.p3.s1"} values={{
          gemGloLink: <Link href={getLocalizedRoute(routes.gemGlo, intl)}>{intl.formatMessage({id: "header.emissions"})} - {intl.formatMessage({id: "geoRegions.Global"})}</Link>,
          gemEurLink: <Link href={getLocalizedRoute(routes.gemEur, intl)}>{intl.formatMessage({id: "header.emissions"})} - {intl.formatMessage({id: "geoRegions.Europe"})}</Link>,
          gemAutLink: <Link href={getLocalizedRoute(routes.gemAt, intl)}>{intl.formatMessage({id: "header.emissions"})} - {intl.formatMessage({id: "geoRegions.Austria"})}</Link>,
          cwmGloLink: <Link href={getLocalizedRoute(routes.cwmGlo, intl)}>{intl.formatMessage({id: "header.warming"})} - {intl.formatMessage({id: "geoRegions.Global"})}</Link>,
          ewmEurLink: <Link href={getLocalizedRoute(routes.ewmEur, intl)}>{intl.formatMessage({id: "header.extremes"})} - {intl.formatMessage({id: "geoRegions.Europe"})}</Link>,
          ewmAutLink: <Link href={getLocalizedRoute(routes.ewmEur, intl)}>{intl.formatMessage({id: "header.extremes"})} - {intl.formatMessage({id: "geoRegions.Austria"})}</Link>,
        }}/>{" "}
        <FormattedMessage id={"home.p3.s2"} values={{b: (...chunks) => <b>{chunks}</b>}}/>
        {" "}<Link href={"https://gcci-documentation.gitbook.io"}>GCCI {intl.formatMessage({id: "home.documentation"})}</Link>{"."}
      </p>
      <p>
        <FormattedMessage id={"home.p4.s1"} values={{
          carbEarthLink: <Link href={carbManageUrl}>CarbManage.Earth</Link>,
        }}/>{" "}
        <FormattedMessage id={"home.p4.s2"} values={{b: (...chunks) => <b>{chunks}</b>}}/>
      </p>
      <p>
        <FormattedMessage id={"home.p5.s1"} values={{b: (...chunks) => <b>{chunks}</b>}}/>
      </p>
    </PageTemplate>
  </LocationContext.Provider>
};

export default Home
